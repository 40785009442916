import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { getCourseNavigation } from "../../../../../store/courses"
import LayoutCourse from "../../../../../layouts/course"
import Box from "../../../../../components/box"
import Paragraph from "../../../../../components/paragraph"
import CourseFooterNext from "../../../../../components/course-footer-next"
import Grid from "../../../../../components/grid"
import Heading from "../../../../../components/heading"
import Icon from "../../../../../components/icon"
import Image from "../../../../../components/image"
import ImageHotspot from "../../../../../components/image-hotspot"
import ImageHotspots from "../../../../../components/image-hotspots"
import ImageHotspotsModalTemplate from "../../../../../components/image-hotspots-modal-template"
import Note from "../../../../../components/note"
import QuotePrimary from "../../../../../components/quote-primary"
import Seo from "../../../../../components/seo"
import Stack from "../../../../../components/stack"
import Text from "../../../../../components/text"

const Page = () => {
  const navigation = getCourseNavigation({ courseId: "haelfte-des-lebens" })

  const data = useStaticQuery(graphql`
    query ManuskriptHaelfteQuery {
      vorderseite: file(
        relativePath: { eq: "kurse/sapphos-schwanengesang-1.png" }
      ) {
        ...largeImage
      }
      rueckseite: file(
        relativePath: { eq: "kurse/sapphos-schwanengesang-2.png" }
      ) {
        ...largeImage
      }
      gedicht: file(
        relativePath: {
          eq: "dauerausstellung/05-haelfte-des-lebens/gedicht-fruehling.png"
        }
      ) {
        ...largeImage
      }
      sappho01: file(relativePath: { eq: "kurse/sappho-01.jpg" }) {
        ...largeImage
      }
      sappho02: file(relativePath: { eq: "kurse/sappho-02.jpg" }) {
        ...largeImage
      }
      sappho03: file(relativePath: { eq: "kurse/sappho-03.jpg" }) {
        ...largeImage
      }
      sappho04: file(relativePath: { eq: "kurse/sappho-04.jpg" }) {
        ...largeImage
      }
      sappho05: file(relativePath: { eq: "kurse/sappho-05.jpg" }) {
        ...largeImage
      }
      sappho06: file(relativePath: { eq: "kurse/sappho-06.jpg" }) {
        ...largeImage
      }
      sappho07: file(relativePath: { eq: "kurse/sappho-07.jpg" }) {
        ...largeImage
      }
    }
  `)

  return (
    <LayoutCourse
      navigation={navigation}
      footer={
        <CourseFooterNext to="/kurse/haelfte-des-lebens/01-zwei-haelften/gedicht-hoeren" />
      }
    >
      <Seo title="Manuskript erforschen" />
      <Box>
        <Stack>
          <Stack space={3}>
            <Heading as="h1" level={2}>
              Manuskript erforschen
            </Heading>
          </Stack>
          <Paragraph>
            Mit der Verwendung des Adonius hat Hölderlin in ›Hälfte des Lebens‹
            also eine Anspielung auf antike Lyrikerin Sappho versteckt hat. Ihr
            Schwanengesang schwingt sozusagen im ganzen Gedicht, vom Titel bis
            zum letzten Vers, mit.
          </Paragraph>
          <Note variant="task">
            <>
              Was kann man aus dieser Handschrift erfahren? Sieh dir das
              Manuskript genauer an und inspiziere die mit dem
              <Box
                as="span"
                display="inline-flex"
                ml={1}
                bg="primary"
                color="background"
                borderRadius="full"
              >
                <Icon size={4} icon="plus" />
              </Box>{" "}
              Zeichen gekennzeichneten Details.
            </>
          </Note>

          <Grid columns={[1, 2]}>
            <Stack space={3}>
              <ImageHotspots
                image={
                  <Image
                    image={data.vorderseite.childImageSharp.gatsbyImageData}
                    alt="Handschriftliches Manuskript von Sapphos Schwanengesang"
                  />
                }
              >
                <ImageHotspot x={25} y={3} name="sappho-01">
                  <ImageHotspotsModalTemplate
                    title="Sappho"
                    image={
                      <Image
                        image={data.sappho01.childImageSharp.gatsbyImageData}
                        alt=""
                      />
                    }
                  >
                    <Paragraph>
                      Die Lieder der griechischen Dichterin Sappho gehören zu
                      den ältesten literarischen Zeugnissen der
                      Menschheitsgeschichte. Obwohl von ihrem literarischen
                      Werk, das einmal mehrere 100 Gedichte umfasste, nur noch
                      ein Bruchteil von etwa 600 Zeilen erhalten ist, gilt sie
                      als wichtigste Lyrikerin der griechischen Antike.
                    </Paragraph>
                    <Paragraph>
                      In ihren eigenen Texten nennt sie sich Ψάπφω (Psapphō), in
                      allen anderen Quellen ist sie als Sappho überliefert. Der
                      Name könnte »numinos« (zugleich schaudervoll und
                      anziehend) oder auch »sauber« bedeuten. Er könnte aber
                      auch von den altgriechischen Bezeichnungen für »Saphir«
                      und »Lapislazuli« abgeleitet sein. Um die Dichterin selbst
                      ranken sich Legenden und Mythen: »So war sie mal ist sie
                      Priesterin, [...] mal liebestolles Mannweib, mal gütige
                      Lehrerin, mal galante Dame; mal schamlos und verdorben,
                      mal sittsam und rein.« (Judith Schalansky: Verzeichnis
                      einiger Verluste, Berlin 2018, S. 123).
                    </Paragraph>
                  </ImageHotspotsModalTemplate>
                </ImageHotspot>
                <ImageHotspot x={40} y={3} name="sappho-02">
                  <ImageHotspotsModalTemplate
                    title="Schwanengesang"
                    image={
                      <Image
                        image={data.sappho02.childImageSharp.gatsbyImageData}
                        alt=""
                      />
                    }
                  >
                    <Paragraph>
                      Der Schwan hat seinen Namen vom indogermanischen »swen«
                      (tönen, schallen, singen) – wahrscheinlich, weil der
                      tiefe, pausenartige Gesang der Singschwäne so
                      ausdrucksvoll und eingängig ist. Ihr umfangreiches
                      Repertoire reicht von gigigi, ga und go, über ein raues uk
                      und ak, bis zu einem weichen kü kü kü.
                    </Paragraph>
                    <Paragraph>
                      Als Symbol steht der Schwan seit der Antike aber auch für
                      den Dichter, der sich durch seinen Gesang von den anderen
                      abhebt. So erhielt Hölderlins Dichtervorbild Pindar von
                      Horaz den Beinamen »dirkäischer Schwan«. Diese Metapher
                      war noch zu Hölderlins Zeit durchaus gängig und auch in
                      Wörterbüchern vorzufinden.
                    </Paragraph>
                    <Paragraph>
                      Als »Schwanengesang« wurde häufig das letzte Werk eines
                      Dichters bezeichnet, nach der antiken Vorstellung, dass
                      der Schwan vor dem Sterben noch ein letztes Mal singt.
                    </Paragraph>
                  </ImageHotspotsModalTemplate>
                </ImageHotspot>
                <ImageHotspot x={35} y={14} name="sappho-03">
                  <ImageHotspotsModalTemplate
                    title="Die ersten Zeilen"
                    image={
                      <Image
                        image={data.sappho03.childImageSharp.gatsbyImageData}
                        alt=""
                      />
                    }
                  >
                    <Paragraph>
                      Wohl im Dezember 1799 beginnt Hölderlin mit den ersten
                      Zeilen des Entwurfs, die sich über das Papier verteilen:
                      »Himmlische Liebe! wenn ich dein vergäße – / ... Wenn dann
                      der süßen Jugend immermahnend / die Erinnerung nur mir
                      blieb? / Ach! ... / Eines wüßt ich«{" "}
                    </Paragraph>
                  </ImageHotspotsModalTemplate>
                </ImageHotspot>
                <ImageHotspot x={48} y={10} name="sappho-04">
                  <ImageHotspotsModalTemplate
                    title="Einfügungen"
                    image={
                      <Image
                        image={data.sappho04.childImageSharp.gatsbyImageData}
                        alt=""
                      />
                    }
                  >
                    <Paragraph>
                      Später reichert Hölderlin die erste Niederschrift weiter
                      an: Um den Ausruf »Himmlische Liebe!« fügt er die
                      Ergänzungen »zärtliche« und »Demüthige!« hinzu.
                    </Paragraph>
                  </ImageHotspotsModalTemplate>
                </ImageHotspot>
                <ImageHotspot x={47} y={28} name="sappho-05">
                  <ImageHotspotsModalTemplate
                    title="An meine Schwester"
                    image={
                      <Image
                        image={data.sappho05.childImageSharp.gatsbyImageData}
                        alt="as"
                      />
                    }
                  >
                    <Paragraph>
                      Inmitten der Versversatzstücke ist eine Widmung zu lesen:
                      »An meine Schwester«
                    </Paragraph>
                  </ImageHotspotsModalTemplate>
                </ImageHotspot>
                <ImageHotspot x={70} y={44} name="sappho-06">
                  <ImageHotspotsModalTemplate
                    title="Thränen"
                    image={
                      <Image
                        image={data.sappho06.childImageSharp.gatsbyImageData}
                        alt=""
                      />
                    }
                  >
                    <Paragraph>
                      Am rechten Rand notiert Hölderlin zunächst untereinander
                      die beiden Sequenzen: »Ihr waichen Thränen / wischet
                      mir...« Doch scheint das Verb noch nicht ganz zu passen
                      und so ersetzt er es durch »Ihr waichen Thränen / löschet
                      das / Augenlicht nicht gar aus«. Darunter korrigiert er
                      sich abermals: »Ihr waichen Thränen / löschet das /
                      Augenlicht / Nur mir aber nicht gänzlich aus!« Im Dezember
                      1803 entwickelt Hölderlin diese Verse dann zu dem Gedicht
                      ›Thränen‹, das mit den folgenden Versen endet:
                    </Paragraph>
                    <QuotePrimary>
                      <p>Ihr waichen Tränen, löschet das Augenlicht</p>
                      <p>Mir aber nicht ganz aus; ein Gedächtnis doch,</p>
                      <p>Damit ich edel sterbe, laßt ihr</p>
                      <p>Trügrischen, Diebischen, mir nachleben.</p>
                    </QuotePrimary>
                  </ImageHotspotsModalTemplate>
                </ImageHotspot>
                <ImageHotspot x={25} y={85} name="sappho-07">
                  <ImageHotspotsModalTemplate
                    title="Tinian."
                    image={
                      <Image
                        image={data.sappho07.childImageSharp.gatsbyImageData}
                        alt=""
                      />
                    }
                  >
                    <Paragraph>
                      Am Blattende reihen sich fragmentarische Notizen, die in
                      die Ferne verweisen: »Der Schiffer.«; »die Inseln der
                      Liebe«; »der Sturm am Vorgebirge«; »der Palme Frucht.«;
                      »Tinian.«
                    </Paragraph>
                    <Paragraph>
                      Der Insel Tinian im Pazifischen Ozean, die von 1667 bis
                      1898 von den Spaniern beherrscht wurde, hat Hölderlin auch
                      einen eigenen Hymnen-Entwurf gewidmet.
                    </Paragraph>
                  </ImageHotspotsModalTemplate>
                </ImageHotspot>
              </ImageHotspots>
              <Text>
                Manuskriptseite aus dem sogenannten Stuttgarter Foliobuch mit
                Hölderlins Gedichtentwurf ›Sapphos Schwanengesang‹, WLB
                Stuttgart,{" "}
                <Box as="span" sx={{ wordBreak: "break-all" }}>
                  Cod.poet.et.phil.fol.63,I,6_18r
                </Box>
              </Text>
            </Stack>
            <Stack space={3}>
              <Image
                image={data.rueckseite.childImageSharp.gatsbyImageData}
                alt=""
              />
              <Text>
                Notation einer sapphischen Odenstrophe auf der Rückseite des
                Gedichtentwurfs ›Sapphos Schwanengesang‹ im Stuttgarter
                Foliobuch. Sie entstand Anfang Dezember 1799.
              </Text>
            </Stack>
          </Grid>

          <Note title="Übrigens">
            <p>
              Über solche in den Versmaßen versteckten Bezüge und Anspielungen
              hat Hölderlins Dichter-Vorbild Friedrich Gottlieb Klopstock einmal
              gesagt:
            </p>
            <p>
              »Überhaupt wandelt das Wortlose in einem guten Gedicht umher wie
              in Homers Schlachten die nur von wenigen gesehenen Götter.«
            </p>
          </Note>
        </Stack>
      </Box>
    </LayoutCourse>
  )
}

export default Page
